<template>
  <div id="we-chat-code">
    <header>
      <img
          class="notice-icon"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice.png"
          alt=""
      />
      <div class="notice-content">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice-content.png"
            alt=""
        />
      </div>
    </header>
    <main>
      <h3>请关注公众号‘51卡包’进行{{ text }}</h3>
      <div
          class="copy-button"
          @click="copyText('51卡包')"
      >
        点击此处复制名称
      </div>
      <div class="code-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/fail/code.jpg"
            alt=""
        />
      </div>
      <div>长按下载二维码，使用微信扫一扫关注</div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ''
    }
  },
  methods: {
    // 复制文案至剪切板
    copyText(text) {
      const inputElement = document.createElement('input')
      inputElement.value = text
      document.body.appendChild(inputElement)
      inputElement.select()
      document.execCommand('copy')
      document.body.removeChild(inputElement)
      this.$toast.success('复制成功')
    }
  },
  created() {
    if (this.$route.query.type === '0') {
      this.text = '查看网贷记录'
    } else if (this.$route.query.type === '1') {
      this.text = '删除网贷记录'
    } else {
      this.text = '查看征信报告'
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes move {
  0% {
    transform: translateX(8.4324rem);
  }

  100% {
    transform: translateX(-11.1622rem);
  }
}

#we-chat-code {
  min-height: 100vh;
  background: #F8F8F8 url("https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/top-background-image.png") no-repeat;
  background-size: 100% 5.653rem;

  header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.8649rem;
    padding-left: 17px;
    background: #FBF6E8;

    .notice-icon {
      width: 0.3784rem;
      margin-right: 0.1351rem;
    }

    .notice-content {
      overflow: hidden;
      width: 8.4324rem;
      height: 0.3784rem;

      img {
        width: 11.1622rem;
        transform: translateX(8.4324rem);
        animation: move 16s linear infinite;
      }
    }
  }

  main {
    overflow: hidden;
    width: 9.147rem;
    height: 9.6rem;
    margin: 0.88rem auto 0;
    background: url("https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/we-chat-code/background.png");
    background-size: 100%;
    text-align: center;
    font-size: 0.32rem;
    color: #2E2F31;

    h3 {
      margin: 0.587rem 0 0.267rem;
      font-weight: 500;
      font-size: 0.373rem;
      color: #2E2F31;
    }

    .copy-button {
      font-size: 0.32rem;
      color: #3E7AFF;
    }

    .code-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.32rem;
      height: 4.32rem;
      margin: 1.28rem auto 0.48rem;
      border: 1px solid #E5E5E5;
      border-radius: 0.213rem;

      img {
        width: 3.733rem;
      }
    }
  }
}
</style>